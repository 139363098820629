<template>
  <body class="one">
    <!-- 搜索组件 -->
    <div style="width: 90%; margin: 0 auto">
      <Search @onClickButton="onClickButton" />
    </div>
    <!-- 添加信息 -->
    <div>
      <Add @addRoute="addRoute" />
    </div>
    <!-- list loging分页加载 -->
    <van-pull-refresh v-model="reactiveParams.refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="reactiveParams.loading"
        :finished="reactiveParams.finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div
          class="divStyle"
          v-for="(item, index) in reactiveParams.list"
          :key="index"
        >
          <div class="flx">
            <div>部门</div>
            <div>{{ item.orgName }}</div>
          </div>
          <div class="flx">
            <div>描述</div>
            <div>{{ item.remark }}</div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </body>
</template>
<script>
  import {
    ref,
    inject,
    reactive,
    toRefs,
    provide,
    getCurrentInstance,
  } from "vue";
  import Search from "@/components/compontSearch/Search";
  import Add from "@/components/componentAdd/index";
  import color from "@/components/componentsJs/color";
  export default {
    components: {
      Search,
      Add,
    },
    setup() {
      color.colormounted();
      const { proxy } = getCurrentInstance();
      const reactiveParams = reactive({
        searchValue: "",
        Menuheader: { placeholder: "请输入部门名称" },
        refreshing: false,
        loading: false,
        finished: false,
        list: [],
        from: {
          pi: 0,
          order: "sort.asc",
          orgType: 1,
        },
        totalPage: 1,
      });
      // 父向子传值provide('传值的名称'，需要传的参数)
      provide("hradertext", reactiveParams.Menuheader);
      //  Search搜索组件
      function onClickButton(e) {
        console.log(e);
      }
      // 跳转添加页
      function addRoute() {}
      // 列表初始化方法
      const onLoad = () => {
        if (reactiveParams.refreshing) {
          reactiveParams.list = [];
          reactiveParams.refreshing = false;
        }
        if (reactiveParams.from.pi < reactiveParams.totalPage) {
          reactiveParams.from.pi = reactiveParams.from.pi + 1;
          lists();
        } else {
          reactiveParams.finished = true;
        }
      };
      // 初始化接口
      const lists = async () => {
        let List = await proxy.$Api.get(
          proxy.$DepartmentInformationApi.sysDepartPage,
          reactiveParams.from
        );
        reactiveParams.totalPage = List.data.data.totalPage;
        List.data.data.data.forEach((item) => {
          reactiveParams.list.push(item);
        });
        console.log(reactiveParams.list);
        reactiveParams.loading = false;
      };
      // const list = async () => {
      //   console.log("123456");
      // };
      const onRefresh = () => {};
      return {
        ...toRefs(reactiveParams),
        reactiveParams,
        onClickButton,
        addRoute,
        onLoad,
        onRefresh,
        lists,
      };
    },
  };
</script>
<style scoped>
  .divStyle {
    width: 90%;
    margin: 0 auto;
    padding: 0.1rem;
    overflow: hidden;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 0.2rem;
    margin-top: 0.2rem;
  }
  body {
    height: 100%;
    background-color: #f3f3f3;
  }
</style>
