<template>
  <div>
    <van-search
      v-model="reactiveParams.searchValue"
      :placeholder="userLocation.placeholder"
      :show-action="true"
      @blur="blurs"
    >
      <template #action>
        <div @click="onClickButton">搜索</div>
      </template>
    </van-search>
  </div>
</template>
<script>
  import { ref, inject, reactive, toRefs } from "vue";
  export default {
    emits: ["onClickButton"],
    setup(props, context) {
      const reactiveParams = reactive({
        searchValue: "",
      });
      const userLocation = inject("Search");
      const onClickButton = () => {
        context.emit("onClickButton", reactiveParams.searchValue);
      };
      const blurs = () => {
        if (reactiveParams.searchValue == "") {
          onClickButton();
        }
      };
      return {
        ...toRefs(reactiveParams),
        reactiveParams,
        userLocation,
        onClickButton,
        blurs,
      };
    },
  };
</script>
<style scoped></style>
